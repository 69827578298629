<template>
  <div>
    <div v-if="loaderStatus" class="spinnerstyle">
      <Spinner />
    </div>
    <div class="login-main-container">
      <div class="h-100 d-flex align-items-center justify-content-center">
        <div class="login-main-sub-container p-0 position-relative">
          <b-row class="m-0 w-100">
            <b-col class="p-0">
              <div
                class="w-100 mt-3 d-flex justify-content-center align-items-baseline"
              >
                <img src="../assets/newimgs/GREYLOGO.svg" alt="" />
              </div>
            </b-col>
          </b-row>
          <b-row class="m-0 w-100 ">
            <b-col class="">
              <div
                class="mt-3 testdetails-container"
                style="padding: 10px; height: calc(100vh - 159px)"
              >
                <div
                  class="login-rightcont-headtxt mb-1"
                  style="border-bottom: 1.5px solid white;"
                >
                  Add Hire3x proctoring extension
                </div>
                <b-row class="mt-3" style="height: 88%">
                  <b-col cols="3">
                    <div class="xi-steps-div">
                      <div
                        class="d-flex p-1 mt-2"
                        :class="{
                          'xi-selected': showStep1Content,
                          'xi-unsel pointer': !showStep1Content,
                        }"
                        @click="step1()"
                      >
                        <div class="xi-sel-bar"></div>
                        <div class="d-flex align-items-center ml-2">
                          <div
                            class="xi-unsel-svg-back d-flex align-items-center justify-content-center"
                          >
                            <img
                              src="../assets/newui-img/extstepblue.svg"
                              alt=""
                            />
                          </div>
                          <div
                            class="ml-3"
                            :class="{
                              'xi-sel-text': showStep1Content,
                              'xi-unsel-text': !showStep1Content,
                            }"
                          >
                            Step 
                          </div>
                        </div>
                      </div>
                      <div class="ext-line-with-text mt-2 w-100">
                        <div class="ext-line w-100"></div>
                        <div class="ext-or-text">OR</div>
                        <div class="ext-line w-100"></div>
                      </div>
                      <div
                        class="d-flex p-1 mt-2"
                        :class="{
                          'xi-selected': !showStep1Content,
                          'xi-unsel pointer': showStep1Content,
                        }"
                        @click="step2()"
                      >
                        <div class="xi-sel-bar"></div>
                        <div class="d-flex align-items-center ml-2">
                          <div
                            class="xi-unsel-svg-back d-flex align-items-center justify-content-center"
                          >
                            <img
                              src="../assets/newimgs/extension/extensionvideo.svg"
                              alt=""
                            />
                          </div>
                          <div
                            class="ml-3"
                            :class="{
                              'xi-sel-text': !showStep1Content,
                              'xi-unsel-text': showStep1Content,
                            }"
                          >
                            Watch video instruction
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex align-items-start xi-alert-div mt-3">
                      <div class="mr-3">
                        <img src="../assets/newui-img/extwarning.svg" alt="" />
                      </div>
                      <div class="xi-warningbox-txt">
                        If you have already added the extension, Click on verify
                        extension and continue

                        <div
                          class="xi-refresh pointer mt-2"
                          @click="reloadPage()"
                        >
                          <img
                            src="../assets/newui-img/extrefresh.svg"
                            class="mr-1"
                            alt=""
                          />
                          Refresh Permission
                        </div>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="9" class="px-4 pt-0 pb-4">
                    <template v-if="showStep1Content">
                      <div
                        class="d-flex align-items-center justify-content-between xi-head"
                      >
                        <div class="xi-main-text">Step</div>
                        <div class="bluebtns ml-3" @click="downloadExtension()">
                          <!-- use btn name stop checking while the audio is checked -->
                          <b-button variant="primary" class="pl-4 pr-4"
                            >Add Extension</b-button
                          >
                        </div>
                      </div>
                      <!-- ********************** STEP 2 TEXT ********************** -->
                      <div class="xi-image-desc">
                        Click on <strong> "Add Extension" </strong>button to add
                        the extension from chrome
                      </div>
                      <div class="d-flex justify-content-center mt-5 w-100">
                        <div
                          class="xi-image-div d-flex justify-content-center align-items-center"
                        >
                          <!-- ********************** STEP 2 IMAGE ********************** -->
                          <img
                            class="h-100 w-100"
                            src="../assets/newimgs/extension-new.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div
                        class="d-flex align-items-center justify-content-between xi-head"
                      >
                        <div class="xi-main-text">Watch video Instruction </div>
                        <div class="bluebtns ml-3" @click="downloadExtension()">
                          <!-- use btn name stop checking while the audio is checked -->
                          <b-button variant="primary" class="pl-4 pr-4"
                            >Add Extension</b-button
                          >
                        </div>
                      </div>
                      <!-- ********************** STEP 2 TEXT ********************** -->
                      <div
                        class="d-flex justify-content-center mt-3"
                        style="height: calc(100vh - 330px)"
                      >
                        <div
                          class="d-flex justify-content-center align-items-center w-100"
                        >
                          <iframe
                            title="Extension Install Process Video"
                            class="ext-video-preview"
                            :src="'https://www.youtube.com/embed/OqNcSPu3rjo'"
                            allowfullscreen
                          ></iframe>
                        </div>
                      </div>
                    </template>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <hr class="mb-0" />
          <div
            class="p-3 d-flex align-items-center justify-content-between bluebtns"
          >
            <b-button
              variant="outline-primary"
              @click="$bvModal.show('leavetestmodal')"
              >Leave Test</b-button
            >
            <b-button
              variant="primary"
              class="ml-5"
              @click="verifyFunc()"
              v-if="!verifyExtension"
              >Verify Extension</b-button
            >
            <div v-if="verifyExtension">
              <b-button
                variant="primary"
                class="ml-5 pl-4 pr-4"
                @click="continueToNext"
                >Continue</b-button
              >
            </div>
          </div>
        </div>
        <b-modal
          id="leavetestmodal"
          size="md"
          centered
          hide-footer
          hide-header
          no-close-on-backdrop
          :no-close-on-esc="true"
        >
          <div
            class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
          >
            Alert !
          </div>
          <div
            @click="$bvModal.hide('leavetestmodal')"
            class="modalcloseicon pointer"
          >
            <img
              class="rotate-on-hover"
              src="../assets/newimgs/closeicon.svg"
              alt=""
            />
          </div>
          <div
            class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
          >
            <div>
              <img src="../assets/newui-img/leavetest.svg" alt="" />
            </div>
            <div class="newui-modal-header mt-4">
              Are you sure you want to leave the test ?
            </div>
            <div class="newui-modal-bodytxt mt-3">
              Please note this action cannot be undone
            </div>
            <div class="bluebtns mt-5">
              <b-button
                class="mr-3 pl-4 pr-4"
                variant="outline-primary"
                @click="$bvModal.hide('leavetestmodal')"
                >Cancel</b-button
              >
              <b-button
                variant="primary"
                class="pl-4 pr-4"
                @click="leaveThetest()"
                >Leave Test</b-button
              >
            </div>
          </div>
        </b-modal>
      </div>
    </div>
    <b-modal id="extensionmodal" centered hide-footer hide-header size="md">
      <div
        class="newmodalheadings mt-4 d-flex align-items-center justify-content-center"
      >
        Please Install Extension
      </div>
      <div
        @click="$bvModal.hide('extensionmodal')"
        class="modalcloseicon pointer"
      >
        <img src="../assets/newimgs/closeicon.svg" alt="" />
      </div>
      <div class="typingtest-completed-txt mt-5 pl-4 pr-4"></div>
      <div class="mt-5 d-flex justify-content-center">
        <img src="../assets/newimgs/extension/modalSvg.svg" alt="" />
      </div>
      <div class="d-flex justify-content-center mt-5 mb-4">
        <div class="bluebtns ml-3">
          <!-- use btn name stop checking while the audio is checked -->
          <b-button
            variant="primary"
            class="pl-4 pr-4"
            @click="downloadExtension()"
            >Install</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Spinner from "../components/fixedcomponents/Spinner.vue";
import testDetails from "../mixins/testDetails";

export default {
  name: "AddBrowserExtension",
  data() {
    return {
      loaderStatus: false,
      verifyExtension: false,
      showStep1Content: true,
    };
  },
  mixins: [testDetails],

  components: {
    Spinner,
  },
  computed: {
    ...mapGetters("sectionVUEX", {
      testDetails: "getTestDetails",
      uuid: "getUUID",
    }),
  },
  created(){
    history.pushState(location.href, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
    gotoactivateextensions() {
      this.$router.push({
        path: "/ActivateExtension",
      });
    },
    goTocommunicationTest() {
      this.$router.push({
        path: "/communicationtest",
      });
    },
    downloadExtension() {
      this.$bvModal.hide("extensionmodal");
      window.open(
        " https://chrome.google.com/webstore/detail/simplifycv-proctoring/gfepljemgkegiccegjbclmhmnodpieoj"
      );
    },

    nextSelfiePage() {
      this.$router.push({
        path: "/snapshots",
      });
    },
    goToTypingTest() {
      this.$router.push({
        path: "/typingtest",
      });
    },
    nextdosanddonts() {
      this.$router.push({
        path: "/dosanddonts",
        query: {
          testDetails: this.testDetails,
        },
      });
    },
    step1() {
      this.showStep1Content = true;
    },
    step2() {
      this.showStep1Content = false;
    },
    checkExtension(id, src) {
      return new Promise((resolve) => {
        let e = new Image();
        e.src = "chrome-extension://" + id + "/" + src;
        e.onload = () => {
          resolve(true);
        };
        e.onerror = () => {
          resolve(false);
        };
      });
    },
    async verifyFunc() {
      this.loaderStatus = true;
      const isExtPresent = await this.checkExtension(
        "gfepljemgkegiccegjbclmhmnodpieoj",
        "images/icon.png"
      );
      if (isExtPresent) {
        this.loaderStatus = false;
        this.verifyExtension = true;
      } else {
        this.loaderStatus = false;
        this.$bvModal.show("extensionmodal");
      }
      return isExtPresent;
    },
    async continueToNext() {
      const verify = await this.verifyFunc();
      if (verify) {
        let config = this.testDetails.config;
        if (config.allowAgent && config.allowExtension) {
          this.goToAgent();
        } else if (config.allowImageAndIdCapture) {
          this.nextSelfiePage();
        } else if (config.typingTest) {
          this.goToTypingTest();
        } else if (config.communicationTest) {
          this.goTocommunicationTest();
        } else {
          this.nextdosanddonts();
        }
      } else {
        this.$bvModal.show("extensionmodal");
      }
    },
    leaveThetest() {
      if(localStorage.getItem("fromCandidate")){
        window.close('','_parent','');
      }
      localStorage.clear();
      this.$router.push({
        path: "/",
        query: {
          uuid: this.uuid,
        },
      });
    },
  },
};
</script>

<style scoped>
.xi-warningbox-txt {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #333333;
}
.xi-steps-div {
  background-color: #ffffff;
  border-radius: 8px;
  height: calc(100vh - 400px);
  padding: 15px;
}

.xi-selected {
  background-color: #20639b;
  border-radius: 8px;
}

.xi-unsel {
  background: #ffffff;
  border: 1px solid rgba(140, 140, 140, 0.33);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.17);
  border-radius: 8px;
}

.xi-sel-bar {
  background-color: #ffffff;
  border-radius: 8px;
  width: 1px;
  height: 45px;
}

.xi-sel-svg-back {
  background-color: #ffffff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 1px solid #4c78ce;
}

.xi-unsel-svg-back {
  background-color: #eef3f7;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.xi-sel-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
}

.xi-unsel-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
}

.xi-alert-div {
  background: #ffffff;
  border: 0.8px solid rgba(140, 140, 140, 0.45);
  box-shadow: 0px 15px 30px rgba(122, 122, 122, 0.2);
  border-radius: 8px;
  padding: 15px;
  height: 125px;
}

.xi-refresh {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #20639b;
}

.xi-main-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #333333;
}

.xi-head {
  padding-bottom: 15px;
  /* border-bottom: 1.5px solid white; */
}

.xi-image-desc {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}

.xi-image-div {
  height: calc(100vh - 400px);
}
</style>
